<template>
  <div class="wrapper">
    <header class="header">
      <div class="container">
        <div class="header-content">
          <div class="logo">
            <img :src="logo" alt="Miracle White" />
          </div>
          <nav class="nav">
            <a href="#">About Us</a>
            <a href="#">NEW</a>
            <a href="#">Product</a>
            <a href="#">Product Authentication</a>
            <a href="#">Contact Us</a>
          </nav>
        </div>
      </div>
    </header>

    <main>
      <section class="verify-section">
        <div class="container">
          <h1>Congratulations!</h1>
          
          <div class="verify-result">
            <img :src="validIcon" alt="Valid" class="valid-icon" />
            <p class="verify-text">Your Miracle White product has been</p>
            <p class="verify-text">successfully verified as genuine. Thank</p>
            <p class="verify-text">you for choosing Hess & Co.</p>
            <p class="verify-text">Laboratories. You can now be assured</p>
            <p class="verify-text">of the premium quality and</p>
            <p class="verify-text">exceptional care that our products are</p>
            <p class="verify-text">known for.</p>
          </div>
        </div>
      </section>

      <section class="product-showcase">
        <div class="container">
          <h3>Radiant Futures, Infinite Beauty:</h3>
          <h3>Ignite Your Journey</h3>
          
          <div class="brand-title">
            <img :src="miracleWhite" alt="MIRACLE WHITE">
          </div>
          
          <div class="product-grid">
            <div class="product-card">
              <div class="badge-wrapper">
                <img :src="bestSellerBadge" alt="Best Seller" class="badge-icon">
              </div>
              <img :src="productMW" alt="Signature Radiance" class="product-image">
              <h4>Signature Radiance<br>Integral Care</h4>
            </div>
            
            <div class="product-card">
              <div class="badge-wrapper">
                <img :src="newBadge" alt="New" class="badge-icon">
              </div>
              <img :src="productCR" alt="Cell Renewal" class="product-image">
              <h4>Cell Renewal<br>Booster</h4>
            </div>
          </div>
        </div>
      </section>
    </main>

    <footer>
      <div class="container">
        <p>© Copyright 2012 - 2024 | All Rights Reserved by Hess & Co.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import logo from '@/assets/MW-logo.png'
import validIcon from '@/assets/valid.png'
import bestSellerBadge from '@/assets/best-seller.png'
import newBadge from '@/assets/new.png'
import productMW from '@/assets/MW-SRIC-1.png'
import productCR from '@/assets/MW-CRB.png'
import miracleWhite from '@/assets/miracle-white.png'

export default {
  name: 'App',
  data() {
    return {
      logo,
      validIcon,
      bestSellerBadge,
      newBadge,
      productMW,
      productCR,
      miracleWhite
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  padding: 20px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  height: 60px;
}

.nav {
  display: flex;
  gap: 30px;
}

.nav a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.verify-section {
  text-align: center;
  padding: 60px 0;
  background: #f5f5f5;
}

.verify-section h1 {
  font-size: 36px;
  margin-bottom: 20px;
  font-family: serif; /* 为“Congratulations!”增加字体为衬线字体 */
}

.verify-text {
  font-family: "Open Sans", sans-serif; /* 使用Open Sans字体 */
  font-size: 16px;
  color: #333; /* 深灰色 */
  line-height: 1.5;
}

.product-showcase h3 {
  font-family: serif; 
  color: #414042;  /* 为 “Radiant Futures, Infinite Beauty: Ignite Your Journey” 设置字体和颜色 */
}
/* 针对 .elementor-6008 .elementor-element 下的 h3 */
.elementor-6008 .elementor-element h3 {
  font-size: 28px;
  color: #333;
  line-height: 1.4;
  font-family: serif;
}
/* 针对 "Signature Radiance Integral Care" 和 "Cell Renewal Booster" 的文字修饰 */
.elementor-widget-text-editor {
  text-align: center;
  font-weight: 600;
  line-height: 1.2em;
}
.verify-result {
  margin-top: 40px;
}

.valid-icon {
  width: 100px;
  margin-bottom: 20px;
}

.verify-result h2 {
  margin-bottom: 15px;
}

.product-showcase {
  padding: 60px 0;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin: 60px auto 0;
  padding: 100px 20px 0; /* 增加顶部内边距，为徽章留出空间 */
}

/* 徽章容器 */
.badge-wrapper {
  position: relative;
  height: 100px; /* 为徽章提供独立的空间 */
  margin-bottom: 40px; /* 增加与产品图片的距离 */
}

/* 徽章样式 */
.badge-icon {
  position: absolute;
  top: -80px; /* 上移徽章 */
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: auto;
}

.product-card {
  position: relative;
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-image {
  width: 100%;
  max-width: 250px;
  margin: 0 auto 25px;
}

.product-card h4 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #333;
  font-weight: normal;
}

.product-card p {
  color: #666;
  font-size: 16px;
  font-weight: 300;
}
.brand-title {
  margin: 80px 0;
  text-align: center;
}

.brand-title img {
  max-width: 300px; /* 根据实际需要调整图片大小 */
  height: auto;
}

@media (max-width: 768px) {
  .product-grid {
    padding-top: 80px;
  }
  
  .badge-wrapper {
    height: 80px;
    margin-bottom: 30px;
  }

  .badge-icon {
    width: 80px;
    top: -60px;
  }

  .product-card {
    margin-top: 15px;
  }
}
footer {
  margin-top: auto;
  background: #404042;
  color: #ffffff;
  padding: 20px 0;
}

footer .container {
  text-align: center;
}

footer p {
  margin: 0;
  font-size: 14px;
}
</style>
